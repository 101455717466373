import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { routesURL } from "../urls";

const excludePaths = [
  routesURL.index,
  routesURL.login,
  routesURL.signup,
  routesURL.blog,
  routesURL.blogArticle.asPath,
  routesURL.pricing,
  routesURL.about,
  routesURL.privacyPolicy,
  routesURL.guides,
  routesURL.startGuide,
  routesURL.dataSourceGuide,
]; // paths that are allowed to be viewed at any screen

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentScreenWidth, setCurrentScreenWidth] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const isExcludeMatch = excludePaths.some(
        (item) => item === router.pathname
      );
      setIsMobile(screenWidth < 768 && !isExcludeMatch);
      setCurrentScreenWidth(screenWidth);
    };

    // Run the resize handler only if we're in a browser environment
    if (typeof window !== "undefined") {
      handleResize(); // Initialize the state with the current window size
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [router]);

  return { isMobile, currentScreenWidth };
};

export default useResponsive;
