import React, { useRef } from "react";
import dynamic from "next/dynamic";
import { useGlobalContext } from "../contexts/global-wrapper/GlobalWrapper";
import cx from "classnames";
import useResponsive from "../hooks/useResponsive";

const Sidebar = dynamic(() => import("./Sidebar"), { ssr: false });
const Header = dynamic(() => import("./Header"), { ssr: false });

const Layout = ({
  children,
  pageProps = {},
  showHeader = true,
  divider = false,
  showCurrentDb = false,
  showChatModeSwitch = false,
  hideNavbar = false,
  showSidebar = false,
}) => {
  const { user } = pageProps;
  const { toggleSidebar, setToggleSidebar } = useGlobalContext();

  const shouldAnimate = useRef(false);
  const { isMobile } = useResponsive();
  if (isMobile)
    return (
      <div className="  h-screen w-full flex items-center justify-center flex-col gap-y-7">
        <div>
          <img
            src="/favicons/apple-touch-icon.png"
            alt="logo"
            className=" cursor-pointer h-[30px] drop-shadow"
          />
        </div>
        <div className=" text-2xl text-center">
          Please use a Deskotp device to access the dasboard.
        </div>
      </div>
    );
  return (
    <div>
      <div className="">
        {showSidebar && (
          <Sidebar
            toggleSidebar={toggleSidebar}
            setToggleSidebar={() => {
              shouldAnimate.current = true;
              setToggleSidebar(!toggleSidebar);
              setTimeout(() => {
                shouldAnimate.current = false;
              }, 10);
            }}
          />
        )}

        <div
          className={cx(
            {
              "ml-[220px]": showSidebar && toggleSidebar,
              "ml-[60px]": showSidebar && !toggleSidebar,
            },
            {
              "mt-[58px]": showHeader,
              "mt-0": !showHeader,
            },
            { "transition-[all] duration-200": shouldAnimate.current }
          )}
        >
          {!hideNavbar && (
            <Header
              user={user}
              showHeader={showHeader}
              divider={divider}
              toggleSidebar={toggleSidebar}
              showCurrentDb={showCurrentDb}
              showChatModeSwitch={showChatModeSwitch}
              showSidebar={showSidebar}
            />
          )}

          <div className=" relative">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
